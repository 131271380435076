/* You can add global styles to this file, and also import other style files */
@import 'styles/global_vars';
@import 'styles/vars';
@import '@mdi/font/scss/materialdesignicons';
@import 'bootstrap';

@import 'styles/global/global';
@import 'styles/global/accordion';
@import 'styles/global/form';
@import 'styles/global/button';
@import 'styles/global/header';
@import 'styles/global/footer';
@import 'styles/global/box';
@import 'styles/global/filter';
@import 'styles/global/families';

@import 'styles/login/login';
