.filter-box {
  @extend .mb-4;
  @extend .p-3;
  //box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  background-color: $filter-box-bgColor;
  
  .filter-button {
    position: relative;
    font-size: 1.2rem;
    
    &::after {
      position: absolute;
      border-style: solid;
      border-width: 0.15em 0.15em 0 0;
      content: '';
      display: inline-block;
      height: 0.45em;
      top: 50%;
      right: 0;
      transform: translateY(-50%) rotate(-45deg);
      transform-origin: center;
      width: 0.45em;
      transition: .2s;
    }
    
    &.collapsed::after {
      transform: translateY(-50%) rotate(135deg);
    }
  }
}