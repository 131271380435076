/**
 * CHECKBOX INTERESTS
 */
.checkbox-container {
  display: block;
  
  .box {
    background-color: $interest-checkbox-bgColor;
    border: 1px solid $interest-checkbox-border-color;
    color: $interest-checkbox-color;
    padding: 15px;
    cursor: pointer;
    
    i {
      font-size: 30px;
    }
  }
  
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  input:checked ~ .box {
    color: $interest-checkbox-color-checked;
    background-color: $interest-checkbox-bgColor-checked;
    border: 1px solid $interest-checkbox-border-color-checked;
  }
}

form.form-spec {
  background-color: $form-bgColor;
  padding: $form-padding;
  color: $app-contrast-color;
  
  label {
    font-weight: bold;
    color: $form-label-color;
  }
}

form {
  .form-legend {
    @extend .mt-4;
    font-size: 0.8em;
  }
}

.password-field-container {
  position: relative;
  padding-right: 25px;
  
  i {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

.form-stars-radio-container {
  display: none;
}

.form-stars-container {
  font-size: 26px;
  color: $box-rating-star-color;
  cursor: pointer;
}