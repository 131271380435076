/**
 * Variables de perosnnalisation
 */
@font-face {
  font-family:"proxima-nova";
  src:
    url("./fonts/proxima-600.ttf") format("truetype"),
    url("./fonts/proxima-600.woff2") format("woff2"),
    url("./fonts/proxima-600.woff") format("woff");
  font-display:auto;font-style:normal;font-weight:600;font-stretch:normal;
}
@font-face {
  font-family:"proxima-nova";
  src:
    url("./fonts/proxima-500.ttf") format("truetype"),
    url("./fonts/proxima-500.woff2") format("woff"),
    url("./fonts/proxima-500.woff") format("woff");
  font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
}
@font-face {
  font-family:"proxima-nova";
  src:
    url("./fonts/proxima-400.ttf") format("truetype"),
    url("./fonts/proxima-400.woff2") format("woff"),
    url("./fonts/proxima-400.woff") format("woff");
  font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
    font-family: 'brand';
    src: url('./fonts/jaguar-bold.woff2') format('woff2'),
         url('./fonts/jaguar-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/**
 * Variables propres à la marque
 * /!\ Ces variables doivent utilisées uniquement dans ce fichier dans le seul but d'automatiser des valeur pour les styles globaux 
 */
$jaguar-dark-grey: #3C3C3B;
$jaguar-black: #000000;
$cod-grey: #141414;
$ship-grey: #444444;
$grey-haze: #F9F9F9;
$phosphorus-blue: #00B2A9;
$alert-red: #D2000A;
$focus-blue: #4A90E2;

$stroke-light: #F9F9F9;
$stroke-medium: #D8D8D8;
$stroke-dark: #8D8D8D;
$stroke-darker: #555555;


$app-bgcolor: $cod-grey;
$app-default-color: white;
$app-contrast-color: $jaguar-dark-grey;
$default-font-family: 'proxima-nova';

/**
 * Surcharges bootstrap
 */
$btn-success-color: white;
$btn-success-bgColor: $phosphorus-blue;


/**
 * BOUTON
 */
$button-font: 'proxima-nova';
$btn-font-size: 12px; // Taille par défaut de tous les .btn
$btn-styles: (
  primary : (
    normal: (
      background-color: $jaguar-dark-grey,
      border-color: $jaguar-dark-grey,
      color: white,
      font-size: 13px,
      line-height: 14px,
      letter-spacing: 1.2px
    ),
    hover: (
      background-color: white,
      border-color: $jaguar-dark-grey,
      color: $jaguar-dark-grey
    ),
    with-arrow: (
      // Pas de changement
    ),
    with-arrow-hover: (
      background-color: white,
      border-color: $jaguar-dark-grey,
      color: $jaguar-dark-grey,
    )
  ),
  secondary : (
    normal: (
      background-color: $stroke-medium,
      border-color: $stroke-medium,
      color: $jaguar-dark-grey,
      font-size: 13px,
      line-height: 14px,
      letter-spacing: 1.2px
    ),
    hover: (
      background-color: white,
      border-color: $jaguar-dark-grey,
      color: $jaguar-dark-grey
    ),
    with-arrow: (
      background-color: transparent,
      border-color: transparent,
      color: $jaguar-black
    ),
    with-arrow-hover: (
      // Pas de changement
    )
  )
);

/**
 * LOADER
 */
$loader-color: $jaguar-dark-grey;

/**
 * Contenu et liens
 */
$content-color: $jaguar-dark-grey;
$content-font-size: 13px;
$content-font-weight: 500;
$content-line-height: 18px;
$link-color: $stroke-dark;
$link-color-hover: $stroke-dark;
$link-text-decoration-hover: underline;

$content-color-large: inherit;
$content-font-size-large: 16px;
$content-font-weight-large: 400;
$content-line-height-large: 24px;

/**
 * dropdown menu
 */
$dropdown-button-color: white;
$dropdown-button-bgcolor: transparent;
$dropdown-button-color-hover: white;
$dropdown-button-bgcolor-hover: $ship-grey;
$dropdown-menu-bgcolor: $ship-grey;
$dropdown-menu-color: $jaguar-dark-grey;
$dropdown-menu-link-color: $jaguar-dark-grey;
$dropdown-menu-link-color-hover: $jaguar-dark-grey;
$dropdown-menu-link-bgcolor-hover: $stroke-dark;
$dropdown-account-menu-link-color: white;
$dropdown-account-menu-link-bgcolor: $ship-grey;
$dropdown-disconnect-menu-link-color: white;
$dropdown-disconnect-menu-link-bgcolor: $alert-red;

/**
 * Top bar
 */
$brand-title-color: white;
$brand-bar-bgcolor: $jaguar-dark-grey;

/**
 * Top bar Menu
 */
$menu-bgcolor: $ship-grey;
$menu-text-color: white;
$menu-text-color-active: white;
$menu-text-bg-color-active: $jaguar-dark-grey;

/**
 * Footer menu
 */
$fmenu-bgcolor: $ship-grey;
$fmenu-text-color: white;
$fmenu-text-color-active: white;
$fmenu-text-bg-color-active: white;

/**
 * Boxes
 */
$box-bgColor: $stroke-medium;
$box-title-color: $jaguar-dark-grey;
$box-description-color: $stroke-dark;
$box-ribbon-bgcolor: $jaguar-dark-grey;
$box-rating-star-color: $jaguar-dark-grey;

/**
 * Interest Checkboxes
 */
$interest-checkbox-bgColor: white;
$interest-checkbox-color: $jaguar-dark-grey;
$interest-checkbox-border-color: $jaguar-dark-grey;
$interest-checkbox-bgColor-checked: $jaguar-dark-grey;
$interest-checkbox-color-checked: white;
$interest-checkbox-border-color-checked: $jaguar-dark-grey;

/**
 * Accordions bootstrap vars
 */
$accordion-button-color: white;
$accordion-button-bgcolor: $jaguar-dark-grey;
$accordion-separator-color: $stroke-dark;

/**
 * Sidebar / Sidebar Boxes
 */
$sb-bgColor: transparent;
$sb-box-bgColor: $stroke-medium;
$sb-box-border-color: transparent;
$sb-meta-color: $jaguar-dark-grey;
$sb-meta-bgColor: white;
$sb-meta-suppr-color: white;
$sb-meta-suppr-bgColor: $stroke-dark;

/**
 * Filter
 */
$filter-box-bgColor: $ship-grey;

/**
 * Carousel
 */
$carousel-box-color: $jaguar-dark-grey;
$carousel-box-bgcolor: $stroke-light;

/**
 * Formulaires
 */
$form-bgColor: $grey-haze;
$form-padding: 1.5rem;
$form-label-color: black;

/**
 * Families
 */
 $family-box-color: white;
 
 /**
  * Scrollable boxes
  */
$scrollable-box-bgColor: $stroke-light;
$scrollable-box-color: black;

 /**
  * Car card box
  */
$car-box-bgColor: white;
$car-box-color: black;