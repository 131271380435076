body {
  background-color: $app-bgcolor;
  font-family: $default-font-family;
  color: $app-default-color;
}

.text-content {
  font-size: $content-font-size;
  font-weight: $content-font-weight;
  color: $content-color;
  line-height: $content-line-height;
  
  &.big {
    font-size: $content-font-size-large;
    font-weight: $content-font-weight-large;
    color: $content-color-large;
    line-height: $content-line-height-large;
  }
  
  a {
    color: $link-color;
    text-decoration: $link-text-decoration-hover;
    
    &:hover {
      color: $link-color-hover;
    }
  }
}

.brand-title {
  font-family: 'brand';
  font-size: 25px;
  text-align: center;
  text-transform: uppercase;
  
  &.big {
    font-size: 30px;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $default-font-family;
  text-transform: uppercase;
}

/**
 * SPINNER
 */

.spinner-overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(white, 0.5);
}
 
.lds-ring {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $loader-color transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/**
 * ALERTS
 */
#alerts-container {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  
  .alert {
    padding-right: 25px;
  }
  
  .close {
    text-decoration: none;
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 15px;
    cursor: pointer;
  }
}

.app-container {
  padding-top: 60px;
  
  @include media-breakpoint-up(md) {
    padding-top: 120px;
  }
}

.icon {
  &.personal {
    &::before {
      display: inline-block;
      font-family: 'iconmoon';
      content: '\e921';
      font-size: 20px;
      font-style: normal;
    }
  }
  &.help {
    &::before {
      display: inline-block;
      font-family: 'iconmoon';
      content: '\e931';
      font-size: 20px;
      font-style: normal;
    }
  }
  &.menu {
    &::before {
      display: inline-block;
      font-family: 'iconmoon';
      content: '\e91b';
      font-size: 20px;
      font-style: normal;
    }
  }
  &.search {
    &::before {
      display: inline-block;
      font-family: 'iconmoon';
      content: '\e938';
      font-size: 20px;
      font-style: normal;
    }
  }
  &.close {
    &::before {
      display: inline-block;
      font-family: 'iconmoon';
      content: '\e90a';
      font-size: 20px;
      font-style: normal;
    }
  }
}

.btn-success {
  &, &:hover {
    border-color: $btn-success-bgColor;
    background-color: $btn-success-bgColor;
    color: $btn-success-color;
  }
}

iframe {
  margin: auto;
  display: block;
  width: 100%;
  height: 200px;
  
  @include media-breakpoint-up(md) {
    height: 500px;
  }
}

#video-presentation {
  position: relative;
  
  #video_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: pointer;
    
    svg {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}