.boxes-families {
  width: 1400px;
  max-width: 100%;
  max-width: 100%;
  margin: auto;
  
  @media( min-width: 1040px )
  {
    display: flex;
    flex-direction: row;
  }
  
  .box-family {
    position: relative;
    padding: 30px 10px;
    background-position: center;
    background-size: cover;
    
    @media( min-width: 1040px )
    {
      flex: 1;
      min-height: 250px;
    }
    
    .centered-content {
      text-align: center;
      
      @media( min-width: 1040px )
      {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        transform: translateY( -50% );
      }
      
      
      h2 {
        font-size: 24px;
        text-transform: uppercase;
        font-family: 'brand';
        color: $family-box-color;
      }
    }
    
    .bottom-content {
      text-align: center;
      
      @media( min-width: 1040px )
      {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        transform: translateY( -50% );
      }
      
      
      h2 {
        font-size: 24px;
        text-transform: uppercase;
        font-family: 'brand';
        color: $family-box-color;
      }
    }
  }
}

.box-uniq-family {
  position: relative;
  padding: 30px 0;
  
  img {
    width: 100%;
  }
  
  .centered-content {
    text-align: center;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    transform: translateY( -50% );
    
    h2 {
      margin-bottom: 30px;
      font-size: 35px;
      text-transform: uppercase;
      font-family: 'brand';
      color: $family-box-color;
      
      @media( max-width: 575px )
      {
        font-size: 20px;
      }
    }
  }
}