/**
 * Fonts
 */
@font-face {
    font-family: 'iconmoon';
    src: url('./fonts/icomoon-premium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

$enable-rounded: false;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 992px,
);

$container-max-widths: (
  sm: 540px,
  md: 960px,
);