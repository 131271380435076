.btn {
  font-family: $button-font;
  font-size: $btn-font-size;
  font-weight: 500;
  text-transform: uppercase;
  
  @each $type, $properties in $btn-styles {
    &-#{$type} {
      padding: 17px 26px 17px 19px;
      border-width: 2px;
      @each $prop, $propValue in map-get($properties, 'normal') {
        #{$prop}: #{$propValue};
      }
      
      &:hover {
        @each $prop, $propValue in map-get($properties, 'hover') {
          #{$prop}: #{$propValue};
        }
      }
      
      &.arrow {
        @if $type == primary {
          // todo
          padding-left: 55px;
          position: relative;
          
          &::before {
            font-family: 'iconmoon';
            content: '\e936';
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 20px;
          }
        }
        @else if $type == secondary {
          padding: 0 0 0 23px;
          position: relative;
          
          &:hover {
            text-decoration: underline;
          }
          
          &::before {
            @extend .mdi;
            @extend .mdi-chevron-right;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            color: #8d8d8d;
            font-size: 20px;
          }
          
          &.return {
            color: $app-default-color;
            
            &:before {
              transform: translateY(-50%) rotate(-180deg);
            }
          }
        }
        
        @each $prop, $propValue in map-get($properties, 'with-arrow') {
          #{$prop}: #{$propValue};
        }
        
        &:hover {
          @each $prop, $propValue in map-get($properties, 'with-arrow-hover') {
            #{$prop}: #{$propValue};
          }
        }
      }
    }
  }
}