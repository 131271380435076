.footer {
  .contact-bar {
    @extend .mb-2;
  }
  
  .footer-menu-bar {
    background-color: $fmenu-bgcolor;
    @extend .p-2;
    //box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    
    ul.page-links {
      padding: 0;
      margin: 0;
      
      li {
        list-style: none;
        display: inline-block;
        padding-right: 15px;
        position: relative;
        
        &::before {
          content: '';
          position: absolute;
          display: block;
          height: 15px;
          width: 1px;
          background-color: $fmenu-text-color;
          top: 50%;
          right: 8px;
          transform: translateY(-50%);
        }
        
        &:last-child {
          padding-right: 0;
          
          &::before {
            display: none;
          }
        }
        
        a {
          text-decoration: none;
          color: $fmenu-text-color;
        }
      }
    }
    
    .social-links {
      ul {
        padding: 0;
        margin: 0;
        
        li {
          list-style: none;
          display: inline-block;
          padding-left: 15px;
          position: relative;
          
          &::before {
            content: '';
            position: absolute;
            display: block;
            height: 15px;
            width: 1px;
            background-color: $fmenu-text-color;
            top: 50%;
            left: 8px;
            transform: translateY(-50%);
          }
          
          &:first-child {
            padding-left: 0;
            
            &::before {
              display: none;
            }
          }
          
          a {
            text-decoration: none;
            color: $fmenu-text-color;
            font-size: 20px;
            
            svg {
              width: 20px;
              fill: $fmenu-text-color;
              margin-top: -5px;
            }
          }
        }
      }
    }
  }
  
  .legal-text {
    font-size: 0.7em;
  }
}