.accordion {
  .accordion-item {
    border: 0;
    border-bottom: 1px solid $accordion-separator-color;
    
    &:last-child {
      border-bottom: 0;
    }
    
    .accordion-button {
      color: $accordion-button-color;
      background-color: $accordion-button-bgcolor;
      
      &:not(.collapsed)::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      }
    }
  }
}