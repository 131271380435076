.box {
  @extend .mb-4;
  position: relative;
  background-color: $box-bgColor;
  height: calc( 100% - 1.5rem );
  cursor: pointer;
  //box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  
  img {
    max-width: 100%;
  }
  
  &.nocursor{
    cursor: auto;
  }
  
  .ribbon {
    @extend .py-1;
    @extend .px-2;
    
    position: absolute;
    left: 0;
    top: 0;
    background-color: $box-ribbon-bgcolor;
    color: white;
    
    &.family {
      font-size: 15px;
      font-family: 'brand';
      width: 100%;
      text-align: center;
      background-position: center;
      background-size: cover;
      text-transform: uppercase;
      border-bottom: 4px solid $app-bgcolor;
    }
  }
  
  .thumbnail {
    background-color: white;
    
    > img {
      width: 100%;
    }
  }
  
  .content {
    @extend .py-3;
    @extend .px-3;
    
    .title {
      color: $box-title-color;
      text-decoration: none;
      font-size: 1rem;
    }
    
    .description {
      color: $box-description-color;
      font-size: 0.8rem;
    }
    
    .rating-stars-container {
      .star {
        color: $box-rating-star-color;
        font-size: 20px;
      }
    }
    
    .rating-comment {
      color: $box-title-color;
      
      &::before {
        content: "«";
        margin-right: 3px;
      }
      &::after {
        content: "»";
        margin-left: 3px;
      }
    }
  }
}

.sidebar {
  background-color: $sb-bgColor;
  
  .sidebox {
    //box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    border: 1px solid $sb-box-border-color;
    
    .meta-infos {
      color: $sb-meta-color;
      background-color: $sb-meta-bgColor;
      
      .key {
        white-space: nowrap;
        font-weight: bold;
      }
      
      .value {
        
      }
      
      .date {
        font-weight: bold;
      }
    }
    
    .meta-infos-supp {
      color: $sb-meta-suppr-color;
      background-color: $sb-meta-suppr-bgColor;
    }
  }
}

// Box carousel
.carousel {
  //box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.4);
  
  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }
  
  .carousel-item {
    .box-content {
      @extend .p-3;
      color: $carousel-box-color;
      background-color: $carousel-box-bgcolor;
      width: 300px;
      position: absolute;
      top: 50%;
      left: 50px;
      transform: translateY(-50%);
      
      .box-title h4 {
        font-weight: 600;
        font-size: 1.2rem;
      }
    }
  }
}

.scrollable {
  overflow: auto;
  height: 400px;
  background-color: $scrollable-box-bgColor;
  color: $scrollable-box-color;
  padding: 15px;
}

// Box car
.car-box {
  background-color: $car-box-bgColor;
  color: $car-box-color;
}