.top-bar {
  width: 100%;
  position: fixed;
  z-index: 10;
  
  .brand-bar {
    background-color: $brand-bar-bgcolor;
    
    .open-menu-btn {
      cursor: pointer;
      margin-left: 15px;
    }
  }
  
  .dropdown-toggle {
    padding: 0;
    background-color: $dropdown-menu-bgcolor;
    border: 0;
    font-family: $default-font-family;
    &::after {
      display: none;
    }
    
    @include media-breakpoint-up(sm) {
      padding: 5px 10px;
    }
  }
  
  .dropdown {
    color: $dropdown-menu-color;
    
    button {
      color: $dropdown-button-color;
      color: $dropdown-button-color;
      background-color: $dropdown-button-bgcolor;
      
      &:hover {
        color: $dropdown-button-color-hover;
        background-color: $dropdown-button-bgcolor-hover;
      }
    }
    
    a.dropdown-item {
      color: $dropdown-menu-link-color;
      text-decoration: uppercase;
      
      &:hover {
        color: $dropdown-menu-link-color-hover;
        background-color: $dropdown-menu-link-bgcolor-hover;
      }
    }
    
    .dropdown-account-item {
      @extend .px-3;
      @extend .py-2;
      color: $dropdown-account-menu-link-color;
      background-color: $dropdown-account-menu-link-bgcolor;
    }
    
    .dropdown-validity-item {
      @extend .px-3;
      @extend .pb-2;
      color: $dropdown-account-menu-link-color;
      background-color: $dropdown-account-menu-link-bgcolor;
      font-size: 0.7em;
    }
    
    a.dropdown-logout-item {
      @extend .px-3;
      @extend .py-1;
      display: block;
      color: $dropdown-disconnect-menu-link-color;
      text-decoration: none;
      background-color: $dropdown-disconnect-menu-link-bgcolor;
    }
  }
  
  .brand {
    color: $brand-title-color; 
    font-family: 'brand';
    text-decoration: none;
    padding-left: 0.5rem;
  }
}

.top-menu-bar {
  background-color: $menu-bgcolor;
  //box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.1);
  display: none;
  
  @include media-breakpoint-up(md) {
    display: block;
  }
  
  &.opened {
    display: block;
  }
  
  .main-menu {
    list-style: none;
    padding: 0;
    margin-bottom: 1.5rem;
    
    li {
      display: inline-block;
      
      a {
        @extend .p-2;
        display: inline-block;
        color: $menu-text-color;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 0.9rem;
        font-weight: 600;
        
        &:hover {
          color: $menu-text-color-active;
          background-color: $menu-text-bg-color-active;
        }
      }
    }
  }
}

.searchbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  
  form {
    background-color: white;
    border-bottom: 1px solid grey;
    
    .search-input {
      background-color: white;
      width: 100%;
      border: 0;
    }
    
    .btn {
      padding: 10px;
      line-height: normal;
    }
  }
  
  .results-list {
    padding: 15px;
    background-color: white;
    color: $black;
    max-height: calc( 100vh - 90px );
    overflow: auto;
    
    .count-results {
      color: grey;
    }
    
    .search-result {
      padding: 15px 0;
      border-bottom: 1px solid #eee;
      cursor: pointer;
    }
  }
}